.body-wrapper {
    margin: 0px !important;
    padding: 0px !important;
}

.breadcrumb-wrapper {
    background-color: #2B2C36  !important;
    color: white;
    margin: 60px 0px 0px 0px !important;
}

.content-wrapper {
    margin: 105px 0px 0px 0px !important;
    padding: 0px !important;
}

.left {
    text-align: left !important;
}
.center {
    text-align: center !important;
}
.right {
    text-align: right !important;
}

.grid-deliverables {
    margin: 0px !important;
    padding: 0px !important;
}

.huge-text {
    font-size: 22pt !important;
    text-align: center !important;
    line-height: 1.1 !important;
}

.label-cell {
    margin: 0px !important;
    width: 150px;
}

.label-field {
    margin-right: 12px !important;
    width: 50px !important;
}
.label-field-wide {
    margin-right: 12px !important;
    width: 60px !important;
}
.label-field-wide2 {
    margin-right: 12px !important;
    width: 80px !important;
}

.balance-positive {
    color: green;
    display: inline;
    font-weight: bold;
    padding-right: 12px;
}

.balance-negative {
    color: red;
    display: inline;
    font-weight: bold;
    padding-right: 12px;
}

.balance-even {
    color: black;
    display: inline;
    font-weight: bold;
    padding-right: 12px;
}

.contract-status-open {
    color: blue;
    font-weight: bold;
}

.contract-status-cancelled {
    color: red;
    font-weight: bold;
}

.contract-status-rejected {
    color: red;
    font-weight: bold;
}

.contract-status-received {
    color: blue;
    font-weight: bold;
}

.contract-status-paid {
    color: green;
    font-weight: bold;
}

.contract-status-voucher {
    color: grey;
    font-weight: bold;
}

.corp-logo {
    margin-right: 1.5em;
}

.item-image {
    display: inline !important;
    width: 70px !important;
}

.pilot-name {
    color: rgb(79, 87, 161);
    font-weight: bold;
}

.clickable-row {
    cursor: pointer;
}

