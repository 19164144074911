.table-contents {
    font-size: 14pt !important;
}
.cell-header {
    text-align: center !important;
    width: 300px !important;
}

.cell-item-label {
    margin: 0px !important;
    padding: 0px !important;
    width: 200px !important;
}

.cell-data {
    margin: 0px !important;
    padding: 0px !important;
    width: 300px;
}

.cell-item-data {
    margin: 0px !important;
    padding-left: 10px !important;
}

.wrapper-rate {
    font-weight: bold;
    text-align: center;
    width: 100%;
}

.wrapper-rate-mobile {
    display: inline;
    font-weight: bold;
}

.wrapper-math {
    font-size: 10pt;
    padding-left: 10px;
    width: 100%;
}

.wrapper-math-mobile {
    display: inline;
    float: right;
    font-size: 10pt;
    padding-right: 10px;
}

.wrapper-value {
    color: green !important;
    display: inline;
    font-weight: bold;
    padding-left: 10px;
}

.wrapper-value-mobile {
    color: green !important;
    display: inline;
    font-weight: bold;
}
